
import { defineComponent, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import useTranslations from '@/composables/useTranslations'
import {
  useBreadcrumbs,
  useStructures,
  useTenant,
  useLocalizations,
} from '@/core'

import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TheHeading from '@/components/TheHeading.vue'

export default defineComponent({
  name: 'Single',

  components: {
    Breadcrumbs,
    TheHeading,
  },

  setup() {
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const { tenant } = useTenant()
    const route = useRoute()
    const { t, locale } = useI18n()
    const { getStructuresBySlugPath, getStructuresChildrens } = useStructures()
    const { gqlStructureQuery, gqlStructureChildrenQuery, gqlContentQuery } =
      useTranslations()
    const { getLocalizationById } = useLocalizations()
    const content = computed(() => {
      return getStructuresBySlugPath(contentSlug.value)
    })
    const parent = computed(() => {
      return getStructuresBySlugPath(parentSlug.value)
    })
    const contentItems = computed(() => {
      const contentTree = getStructuresChildrens(content.value.id).map(
        (child) => {
          if (child.type === 'DIRECTORY') {
            child.contentData = getStructuresChildrens(child.id).map((ci) => {
              ci.contentData =
                getLocalizationById(
                  ci.contentData?.id,
                  locale.value,
                  'content',
                ) || ci.contentData

              return ci
            })
            child.contentType = 'section'
          }
          if (child.type === 'OBJECT') {
            child.contentData =
              getLocalizationById(
                child.contentData?.id,
                locale.value,
                'content',
              ) || child.contentData
          }

          return child
        },
      )
      return contentTree
    })
    const singleBreadcrumb = computed(() => route.params.slugPath?.length < 3)
    const parentSlug = computed(() => {
      const { slugPath } = route.params
      if (!Array.isArray(slugPath)) return slugPath

      let path = `${slugPath.slice(0, -1).join('/')}`

      if (route.name === 'ExerciseSingle') {
        path = `${slugPath.slice(0, -2).join('/')}`
      }

      return path
    })
    const parentBreadcrumbSlugPrefix = computed(() => {
      let prefix = 'samarbeid'

      if (route.name === 'ExerciseSingle') {
        prefix = 'oppgaver'
      }

      return prefix
    })
    const breadcrumbsArr = computed(() => {
      const arr = []
      if (route.name === 'ExerciseSingle') {
        arr.push({
          name: t('VIEW_EXERCISES'),
          to: '/oppgaver',
        })
      }
      if (route.name === 'InterdisciplinarySingle') {
        arr.push({
          name: t('VIEW_INTERDISCIPLINARY'),
          to: '/samarbeid',
        })
      }
      arr.push({
        name: parent.value.name,
        to: `/${parentBreadcrumbSlugPrefix.value}/${parentSlug.value}`,
      })
      if (route.name === 'ExerciseSingle') {
        arr.push({
          name: `${
            getStructuresBySlugPath(
              contentSlug.value.split('/').slice(0, -1).join('/'),
            ).name
          } - ${content.value.name}`,
        })
      } else {
        arr.push({
          name: content.value.name,
        })
      }
      return arr
    })
    const contentSlug = computed(
      () =>
        `${
          route.params.slugPath && (route.params.slugPath as string[]).join('/')
        }`,
    )
    const pageTitle = computed(() => {
      let title = content.value && content.value.name
      if (route.name === 'ExerciseSingle') {
        title = `${
          getStructuresBySlugPath(
            contentSlug.value.split('/').slice(0, -1).join('/'),
          ).name
        } ${title}`
      }
      return `${title} ${
        (!singleBreadcrumb.value && parent.value.name) || ''
      } - ${tenant.value.name}`
    })
    useHead({
      title: computed(() => pageTitle.value),
    })

    const loadContent = async () => {
      await gqlStructureQuery(contentSlug.value, locale.value, {})
      if (route.name === 'ExerciseSingle') {
        await gqlStructureQuery(
          contentSlug.value.split('/').slice(0, -1).join('/'),
          locale.value,
          {},
        )
      }
      await gqlStructureQuery(parentSlug.value, locale.value, {})
      await gqlStructureChildrenQuery(contentSlug.value, locale.value, {})
      if (singleBreadcrumb.value) {
        setBreadcrumbs([
          {
            name: content.value.name,
          },
        ])
      } else {
        setBreadcrumbs(breadcrumbsArr.value)
      }

      await Promise.all(
        contentItems.value.map(async (ci) => {
          if (ci.type === 'DIRECTORY') {
            await gqlStructureChildrenQuery(ci.id, locale.value, {})
          }
          if (ci.contentId) {
            await gqlContentQuery(ci.contentId, locale.value, {})
          }

          return ci
        }),
      )

      await Promise.all(
        contentItems.value.map(async (pi) => {
          if (pi.type === 'DIRECTORY') {
            pi.contentData.forEach((ci) => {
              if (ci.contentId) {
                gqlContentQuery(ci.contentId, locale.value, {})
              }
            })
          }

          return pi
        }),
      )
    }

    watch(locale, () => {
      loadContent()
    })

    onMounted(async () => {
      clearBreadcrumbs()
      loadContent()
    })

    return {
      content,
      contentItems,
    }
  },
})
