import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-single-content__wrapper" }
const _hoisted_2 = { class: "v-single-content__header" }
const _hoisted_3 = { class: "v-single-content__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_ContentRenderer = _resolveComponent("ContentRenderer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'v-single-content',
      `v-single-content--${_ctx.$route.name.toLowerCase()}`,
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TheHeading, { level: "h1" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.content.name), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Breadcrumbs),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.contentItems.length)
          ? (_openBlock(), _createBlock(_component_ContentRenderer, {
              key: 0,
              "content-items": _ctx.contentItems,
              "has-toc": true
            }, null, 8, ["content-items"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}